import axios from 'axios';
import tippy from 'tippy.js';
import { WebStorage } from 'vue-ls/src/storage/WebStorage';
import * as common from './location-common';

export { isGeolocationSupported } from './location-common';

const storageKey = 'location';
const storageExpiration = 2592000000; // 30 days
const storage = window.localStorage ? new WebStorage(window.localStorage) : null;

function getSubscribers() {
    window.SngLocationSubscribers = window.SngLocationSubscribers || [];

    return window.SngLocationSubscribers;
}

export function setLocation (location) {
    if (!location || !location.name) {
        location = null;

        if (storage) {
            storage.remove(storageKey);
        }
    } else if (storage) {
        storage.set(storageKey, location, storageExpiration);
    }

    getSubscribers().forEach(subscriber => subscriber(location));
}

export function getLocation () {
    // Return null if the storage is not available on some old devices
    if (!storage) {
        return null;
    }

    return storage.get(storageKey) || null;
}

export function subscribe (callback) {
    getSubscribers().push(callback);
}

export function initGeolocation(buttonEl, onSelectionCallback = () => {}) {
    tippy(buttonEl, { content: window.AppLabels.geolocation });

    common.initGeolocation(buttonEl, (coordinates) => {
        setLocationFromCoordinates(coordinates);
        onSelectionCallback(coordinates);
    });
}

export function initLocationSearch(inputEl, resultsEl, onSelectionCallback = () => {}) {
    common.initLocationSearch(inputEl, resultsEl, value => {
        setLocation(value);
        onSelectionCallback(value);
    });
}

export function initPageSearch(onLinkClick = () => {}) {
    const form = document.querySelector('[data-search-form]');

    if (!form) {
        return;
    }

    const input = form.querySelector('[data-search-form-input]');
    const locationBox = form.querySelector('[data-search-form-location]');

    let timeout;

    const executeSearch = () => {
        common.searchLocations(input.value).then(result => {
            const query = result.query.replace(/ /g, "").toLowerCase();

            const hasResults = result.locations.length === 0 || result.locations.some(record => {
                // Logic taken from the autoComplete library
                const recordLowerCase = record.name.toLowerCase();
                let searchPosition = 0;

                for (let number = 0; number < recordLowerCase.length; number++) {
                    if (searchPosition < query.length && recordLowerCase[number] === query[searchPosition]) {
                        searchPosition++;
                    }
                }

                return searchPosition === query.length;
            });

            if (hasResults) {
                locationBox.classList.add('active');
            } else {
                locationBox.classList.remove('active');
            }
        }).catch(() => {
            locationBox.classList.remove('active');
        });
    }

    input.addEventListener('keyup', () => {
        if (timeout !== undefined) {
            clearTimeout(timeout);
        }

        timeout = setTimeout(executeSearch, 500);
    });

    form.querySelector('[data-search-form-location-link]').addEventListener('click', (e) => {
        e.preventDefault();
        onLinkClick(input.value);
    });

    if (input.value) {
        executeSearch();
    }
}

export function getDetailedLocationData(location) {
    return new Promise((resolve, reject) => {
        const url = 'https://api3.geo.admin.ch/rest/services/all/MapServer/identify';
        const params = {
            'geometry': `${location.y},${location.x}`,
            'geometryFormat': 'geojson',
            'geometryType': 'esriGeometryPoint',
            'layers': 'all:ch.kantone.cadastralwebmap-farbe',
            'returnGeometry': 'true',
            'sr': 2056,
            'tolerance': 0,
        };

        axios.get(url, { params }).then(response => {
            if (response.data.results.length > 0) {
                resolve(response);
            } else {
                // Try a different layer if no results are available
                // (i.e. when map marker is put on the middle of a lake)
                axios.get(url, {
                        params: {
                            ...params,
                            layers: 'all:ch.swisstopo.swissboundaries3d-kanton-flaeche.fill'
                        }
                    }
                ).then(response => {
                    // Override the bbox and geometry because it must be a custom location and we don't want to have a huge bbox
                    // e.g. in case of a lake
                    if (response.data.results[0]) {
                        response.data.results[0].bbox = null;
                        response.data.results[0].geometry = null;
                    }

                    resolve(response);
                }).catch(reject);
            }
        }).catch(reject);
    });
}

export function getCantonData(url, canton) {
    return new Promise((resolve, reject) => {
        axios.get(`${url}/${canton}`).then(response => resolve(response.data)).catch(reject);
    });
}

export function setLocationFromCoordinates(coordinates) {
    setLocation({
        name: `${coordinates[0].toFixed(2)}, ${coordinates[1].toFixed(2)}`,
        y: coordinates[0],
        x: coordinates[1],
    });
}

export function validateInput(inputEl, onValidCallback = () => {}) {
    const coordinates = common.validateInput(inputEl);

    // Set the location from coordinates
    if (coordinates !== null) {
        setLocationFromCoordinates(coordinates);
        onValidCallback(coordinates);
    } else {
        const location = getLocation();

        // Execute the callback if the current input value matches the current location name
        if (location && inputEl.value === location.name) {
            onValidCallback();
        }
    }
}
